import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    coversOuter: {
      width: '90%',
      maxWidth: '299px',
    },
    covers: {
      position: 'relative',
      width: '100%',
      height: '0',
      paddingBottom: '60.52%',
      overflow: 'hidden',
      maskImage: 'url(/img/collection-item/mask-c.png)',
      maskRepeat: 'no-repeat',
      maskSize: 'cover',
      boxShadow: 'inset 0px -12px 0px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#4070CE',
    },
    coversGrid: {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
    },
    coverImage: {
      display: 'block',
      width: '81px',
      height: '122px',
      border: '2px solid #FFF',
      borderRadius: '4px',
      marginBottom: '4px',
      backgroundColor: '#ccc',
      filter: 'drop-shadow(0px 5px 20px rgba(0,0,0,0.1))',
      objectFit: 'cover',
    },
    collectionShadow: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: '0',
    },
    collectionMask: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: '0',
    },
  }
});

const NUMBER_OF_COLUMNS = 5;
const NUMBER_OF_ROWS = 3;
const NUMBER_OF_COVERS = NUMBER_OF_COLUMNS * NUMBER_OF_ROWS;

const getRepeatingHacks = (hcks) => {
  const hacks = [];
  const emptyArray = [...Array(NUMBER_OF_COVERS).keys()];

  emptyArray.forEach((_item, i) => {
    const index = i % hcks.length;
    const hack = hcks[index];
    if (hack?.coverUrl) {
      hacks.push(hack.coverUrl);
    }
  });

  return hacks;
};

const CollectionHackCovers = ({
  hacks,
  backgroundColor
  // scale,
}) => {
  const classes = useStyles();
  const coverUrls = useMemo(() => {
    return getRepeatingHacks(hacks.filter(Boolean));
  }, []);

  const columns = [...Array(NUMBER_OF_COLUMNS).keys()];

  return (
    <div className={classes.coversOuter}>
      <div className={classes.covers} style={{ backgroundColor }}>
        <div className={classes.coversGrid} style={{ transform: 'rotate(-23.48deg)' }}>
          {columns.map((column, i) => {
            const covers = coverUrls.slice(
              i * NUMBER_OF_ROWS,
              i * NUMBER_OF_ROWS + NUMBER_OF_ROWS,
            );
            return (
              <div key={column} style={{ marginTop: i % 2 === 0 ? 0 : -60 }}>
                {covers.map((uri, index) => (
                  <img className={classes.coverImage} src={uri} key={`${uri}_${index}`} />
                ))}
              </div>
            )
          })}
        </div>
        <img className={classes.collectionShadow} src="/img/collection-item/shadow-c.png" />
      </div>
    </div>
  );
};

export default CollectionHackCovers;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'relative',
      marginTop: '-17px',
      marginLeft: '20px',
      width: 'fit-content',
      padding: '2px 8px 6px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5.48px',
      color: '#FFF',
      fontSize: '12.33px',
      lineHeight: '14.62px',
      fontWeight: '700',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    secondaryBg: {
      position: 'absolute',
      top: '-2px',
      left: '0',
      width: '100%',
      height: '100%',
      borderRadius: '5.48px',
      backgroundColor: theme.palette.secondary.main,
      transform: 'skewY(-0.03rad) skewX(0.06rad)',
    },
    text: {
      position: 'relative',
    }
  }
})

const CollectionItemHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <div className={classes.secondaryBg} />
      <span className={classes.text}>
        {t('collectionItem.label')}
      </span>
    </div>
  )
}

export default CollectionItemHeader;

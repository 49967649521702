import React from 'react';
import { graphql, Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import makeStyles from '@material-ui/styles/makeStyles';
import CollectionItem from '../components/CollectionItem/CollectionItem';
import DownloadComponent from '../components/DownloadComponent/DownloadComponent';
import AppBar from '../components/AppBar/AppBar';
import Footer from '../components/Footer/Footer';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      paddingTop: '32px',
      backgroundColor: '#F8F8F8',
      [theme.breakpoints.up(1200)]: {
        paddingTop: '152px',
      },
    },
    title: {
      fontSize: '21px',
      lineHeight: '30px',
      fontWeight: '500',
      padding: '0 24px',
      maxWidth: '600px',
      margin: '0 auto 16px',
      [theme.breakpoints.up(1200)]: {
        maxWidth: '1218px',
        fontSize: '34px',
        lineHeight: '40px',
        fontWeight: '700',
        marginBottom: '24px',
      },
    },
    collectionsList: {
      padding: '0 24px',
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      maxWidth: '600px',
      margin: '0 auto 32px',
      [theme.breakpoints.up(1200)]: {
        maxWidth: '1218px',
        flexDirection: 'row',
        gap: '28px',
        flexWrap: 'wrap',
      },
    },
    navigationContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '0 50px',
      marginBottom: '32px',
      '& a': {
        width: '100%',
        fontSize: 16,
        color: theme.palette.text.primary,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  };
});

const CollectionsIndexPage = ({ data, pageContext: { totalPages, currentPage, language } }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const collections = data.allCollection.nodes;
  const hacks = data.allHack.nodes;
  return (
    <>
      <AppBar keepBackgroundColor />
      <main className={classes.root}>
        <Typography className={classes.title} variant="h1">
          {t('collectionListPage.title')}
        </Typography>
        <div className={classes.collectionsList}>
          {collections.map(collection => {
            const collectionHacks = hacks.filter(({ jsonId }) => {
              return collection.collectionHacks.map((hack) => hack.hackId).includes(jsonId);
            });
            return <CollectionItem key={collection.id} collection={collection} hacks={collectionHacks} />
          })}
        </div>
        <div className={classes.navigationContainer}>
          <Pagination
            count={totalPages}
            page={currentPage}
            renderItem={item => (
              <PaginationItem
                component={Link}
                to={`${language.startsWith('en') ? '' : `/${language}`}/collections${item.page === 1 ? '' : `/${item.page}`}`}
                {...item}
              />
            )}
          />
        </div>
        <DownloadComponent showPhones />
      </main>
      <Footer />
    </>
  );
};
export default CollectionsIndexPage;

export const query = graphql`
  query CollectionsIndexQuery($limit: Int, $skip: Int, $hackIds: [String], $language: String) {
    allCollection(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
      filter: { isPublished: { eq: true }, locale: { eq: $language } }
    ) {
      nodes {
        id
        collectionHacks {
          hackId
        }
        coverUrl
        description
        isPublished
        title
        tags
        backgroundColor
        slug
        locale
      }
    }
    allHack(filter: { jsonId: { in: $hackIds } }) {
      nodes {
        jsonId
        coverUrl
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import makeStyles from '@material-ui/styles/makeStyles';
import { Typography } from '@material-ui/core';
import CollectionItemHeader from './CollectionItemHeader';
import CollectionHackCovers from './CollectionHackCovers';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      maxWidth: '371px',
      margin: '0 auto',
      background: '#fff',
      borderRadius: '0 8px 8px 8px',
      textDecoration: 'none',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      [theme.breakpoints.up(1200)]: {
        // width: '371px',
        flex: '0 0 calc(33.333333% - 19px)',
      },
    },
    image: {
      paddingTop: '171px',
      height: 0,
      width: '100%',
      borderRadius: '0 8px 8px 8px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    details: {
      padding: '20px 24px 24px',
    },
    tagsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      flexWrap: 'wrap',
      gap: '8px',
      marginBottom: '8px',
    },
    tag: {
      padding: '4px',
      fontSize: '12px',
      lineHeight: '14px',
      color: '#FFF',
      fontWeight: '400',
      backgroundColor: '#E9E9EC',
    },
    title: {
      fontSize: '21px',
      lineHeight: '30px',
      fontWeight: '500',
      color: '#FFF',
      marginBottom: '16px',
    },
    description: {
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: '400',
      color: '#FFF',
    },
    coversOuter: {
      width: '90%',
    },
    covers: {
      position: 'relative',
      width: '100%',
      height: '0',
      paddingBottom: '60.52%',
      overflow: 'hidden',
      maskImage: 'url(/img/collection-item/mask-c.png)',
      maskRepeat: 'no-repeat',
      maskSize: 'cover',
      boxShadow: 'inset 0px -12px 0px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#4070CE',
    },
    coversGrid: {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
    },
    coverImage: {
      display: 'block',
      width: '81px',
      height: '122px',
      border: '2px solid #FFF',
      borderRadius: '4px',
      marginBottom: '4px',
      backgroundColor: '#ccc',
      filter: 'drop-shadow(0px 5px 20px rgba(0,0,0,0.1))',
      objectFit: 'cover',
    },
    collectionShadow: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: '0',
    },
    collectionMask: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: '0',
    },
  };
})

const CollectionItem = ({
  collection,
  hacks,
}) => {
  const classes = useStyles();
  return (
    <Link
      className={classes.root}
      style={{ backgroundColor: collection.backgroundColor }}
      to={`${collection.locale.startsWith('en') ? '' : `/${collection.locale}`}/collection/${collection.slug}`}
    >
      <CollectionHackCovers hacks={hacks} backgroundColor={collection.backgroundColor} />
      <CollectionItemHeader />
      <div className={classes.details}>
        <Typography className={classes.title} variant="body1">
          {collection.title}
        </Typography>
        <Typography className={classes.description} variant="body1">
          {collection.description}
        </Typography>
      </div>
    </Link>
  );
}

export default CollectionItem;
